<template>
  <div>
    <van-cell-group>
      <van-cell title="户主关系" :value="hzlist.RelationHouserName" />
      <van-cell title="户口状态" :value="hzlist.HouserStatusName" />
      <van-cell title="姓名" :value="hzlist.Name" />
      <van-cell title="身份证号" :value="hzlist.IdNumber"/>
      <van-cell title="性别" :value="hzlist.SexDesc" />
      <van-cell title="出生日期" :value="hzlist.Birthday" />
      <van-cell title="民族" :value="hzlist.NationName" />
      <van-cell title="联系电话" :value="hzlist.MobileTel" />
      <!-- <van-cell title="独生子女" :value="hzlist.IsOnlyChildDesc" /> -->
      <van-cell title="婚姻状况" :value="hzlist.MarriageName" />
      <van-cell title="住址" :value="hzlist.RegisterAddr" />
      <van-cell title="政治面貌" :value="hzlist.PoliticalName" />
      <!-- <van-cell title="宗教信仰" :value="hzlist.ReligionName" /> -->
      <!-- <van-cell title="健康状况" :value="hzlist.HealthyCircsDesc" /> -->
      <!-- <van-cell title="状态" :value="hzlist.StatusDesc" /> -->
    </van-cell-group>
  </div>
</template>
<script>
import { GetResident } from "@/api/wangge";
import { getwgToken} from "@/utils/auth";
export default {
  data() {
    return {
        listfrom:{},
        hzlist:[]
    }
  },
  created() {
    this.cyXq();
  },
  methods: {
    // 获取住户列表
    cyXq() {
        console.log(this.$route.query);
      this.listfrom.hId = this.$route.query.HId;
      this.listfrom.accToken = getwgToken();
      GetResident(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.hzlist = {};
            } else {
              this.hzlist = res.data.data;
            //   for (var i = 0; i < this.dylist.length; i++) {
            //     var item = this.dylist[i].IssTime;
            //     item = this.dataFormat(item);
            //     this.dylist[i].IssTime = item;
            //     console.log();
            //     this.fwList()
            //   }
            }
          }
        })
        .catch(() => {});
    },
  },
}
</script>
<style scoped>
.van-cell{
    font-size: 15px !important;
}
.rightClass{
  width:60% !important;
}
</style>